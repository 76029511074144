import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, ElementRef, HostBinding, inject, Injector, OnDestroy, output, signal, viewChild } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'

import { produce } from 'immer'
import { cloneDeep } from 'lodash'
import { combineLatestWith } from 'rxjs'

import { ChartComponent } from '#modules/workspace/components/element/chart/chart.component'
import { ReplaceWithUploadComponent } from '#modules/workspace/components/replace-with-upload'
import { isChartSetting } from '#modules/workspace/components/setting-panel/util'
import { FileUploadService } from '#modules/workspace/components/upload/upload.service'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { IChartSetting, ISize } from '#modules/workspace/types/element'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { DataGridComponent, ExcelData } from '#shared/components'
import { ButtonComponent } from '#shared/components/button/button.component'
import { IMenu, MenuComponent } from '#shared/components/menu/menu.component'

@Component({
  selector: 'ace-chart-data-visual',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent, MenuComponent, ChartComponent, DataGridComponent, MatIconButton],
  templateUrl: './chart-data-visual.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartDataVisualComponent implements OnDestroy {
  @HostBinding('class') class = 'w-full h-full'

  workspaceService = inject(WorkspaceService)

  aceClose = output()

  resetOptions: IMenu[] = [
    { text: '从本地替换', icon: 'editorup:upload-local' },
    { text: '从上传数据替换', icon: 'editorup:upload', callback: this.replaceFromUpload.bind(this) }
  ]

  downloadOptions: IMenu[] = [
    { text: '下载至本地', icon: 'editorup:download', callback: this.handleDownloadLocal.bind(this) },
    { text: '保存数据至已上传', icon: 'upload', callback: this.handleUpload.bind(this) }
  ]

  shadowSetting = signal<IChartSetting | undefined>(undefined)
  chartSize = signal<ISize>({
    width: 400,
    height: 400
  })
  chartScale = signal(1)

  /**
   * 自己维护一个data而不从store中获取，否则每次更改会导致handsontable进行loadData时丢失redo和undo
   */
  chartData = signal<ExcelData[][]>([[]])
  // 保存用于重置数据
  resetData: ExcelData[][] = [[]]

  dataGridRef = viewChild.required(DataGridComponent)

  chartElement = computed(() => {
    const selectedElements = this._uiStore.selectedElements()
    return selectedElements.length === 1 && isChartSetting(selectedElements[0]) ? selectedElements[0] : null
  })

  chartContainerRef = viewChild<ElementRef<HTMLDivElement>>('chartContainerRef')

  /**
   * 用于最后组件销毁时是否触发data数据更新的判断
   */
  _changed = false
  _overlay = inject(Overlay)
  _overlayRef: OverlayRef | undefined
  _replaceWithUploadPortal: ComponentPortal<ReplaceWithUploadComponent> | undefined
  _injector = inject(Injector)
  _uiStore = inject(StageUiStore)
  uploadService = inject(FileUploadService)

  constructor() {
    toObservable(this.chartElement)
      .pipe(combineLatestWith(toObservable(this.chartContainerRef)), takeUntilDestroyed())
      .subscribe(([element, container]) => {
        if (element && container) {
          // 仅渲染一个和宽度相同的正方形
          const { width: cw } = container.nativeElement.getBoundingClientRect()
          const { width, height } = element.size

          this.chartSize.set({ width, height })
          this.chartScale.set(width > height ? cw / width : cw / height)
          this.shadowSetting.set(element.setting)
          // 保存一个data副本用于第一次渲染
          const data = cloneDeep(element.setting?.data[0] || [[]])
          this.resetData = cloneDeep(data)
          this.chartData.set(data)
        }
      })
  }

  /**
   * 组件销毁时，如果有过修改数据的行为，那么更新一次数据
   */
  ngOnDestroy() {
    if (this._changed) {
      const element = this.chartElement()
      const data = this.shadowSetting()?.data
      if (element && data) {
        this._uiStore.setSettingElement(
          element.id,
          produce(element.setting, draft => {
            draft.data = data
          })
        )
        this._uiStore.resetInteractingElement()
      }
    }
  }

  handleDataChange($event: ExcelData[][]) {
    this._changed = true
    this.shadowSetting.update(
      produce(draft => {
        if (draft) {
          draft.data[0] = $event
        }
      })
    )
  }

  replaceFromUpload() {
    if (!this._replaceWithUploadPortal || !this._overlayRef) {
      this._overlayRef = this._overlay.create({
        hasBackdrop: true,
        backdropClass: ['bg-black', 'bg-opacity-70'],
        positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
        disposeOnNavigation: true
      })
      this._replaceWithUploadPortal = new ComponentPortal(ReplaceWithUploadComponent, null, this._injector)
      this._overlayRef.outsidePointerEvents().subscribe(() => {
        this._overlayRef?.detach()
      })
    }
    const componentRef = this._overlayRef.attach(this._replaceWithUploadPortal)
    componentRef.instance.aceCancel.subscribe(() => {
      this._overlayRef?.detach()
    })
    componentRef.instance.aceConfirm.subscribe(uploadData => {
      // Load Data
      console.log('upload data', uploadData)
      this._overlayRef?.detach()
      const data = JSON.parse(uploadData.raw)
      this.chartData.set(data)
      this.handleDataChange(data)
    })
  }

  handleClose() {
    this.aceClose.emit()
  }

  handleResetData() {
    this.chartData.set(this.resetData)
    this.handleDataChange(this.resetData)
    this.dataGridRef().instance.loadData(cloneDeep(this.resetData))
    this._changed = false
  }

  handleDownloadLocal() {
    this.dataGridRef().downloadExcel()
  }

  handleUpload() {
    this.workspaceService.uploadData(this.shadowSetting()?.data[0] as ExcelData[][])
  }
}
