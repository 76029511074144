<div class="flex h-full w-full flex-col overflow-hidden rounded-t-xl bg-white">
  <div class="h-18 flex flex-shrink-0 items-center justify-between px-4">
    <div class="mb-2 flex h-10 items-center text-lg">
      <mat-icon svgIcon="editorup:listpoint (节点)" color="primary" class="mr-2 h-5 min-h-5 w-5 min-w-5"></mat-icon>
      <span class="text-tertiary-900 text-lg">编辑数据</span>
    </div>
    <div class="flex items-center">
      <ace-menu text="替换数据" icon="editorup:replace" [options]="resetOptions"></ace-menu>
      <div class="w-3 flex-shrink-0"></div>
      <ace-button text="重置数据" icon="editorup:reset" (aceClick)="handleResetData()"></ace-button>
      <div class="w-3 flex-shrink-0"></div>
      <ace-menu text="下载数据" icon="editorup:download" [options]="downloadOptions"></ace-menu>
      <div class="w-10 flex-shrink-0"></div>
      <button mat-icon-button (click)="handleClose()">
        <mat-icon svgIcon="editorup:Close (关闭)" class="icon-size-4"></mat-icon>
      </button>
    </div>
  </div>

  <div class="flex h-0 flex-grow items-center overflow-hidden">
    <!--  数据编辑-->
    <div class="h-full flex-grow">
      <ace-data-grid [aceData]="chartData()" (aceChange)="handleDataChange($event)"></ace-data-grid>
    </div>
    <div class="w-100 flex h-full flex-shrink-0 flex-col">
      <div class="mb-2 flex h-10 items-center text-lg">
        <mat-icon svgIcon="editorup:listpoint (节点)" color="primary" class="mr-2 h-5 min-h-5 w-5 min-w-5"></mat-icon>
        <span class="text-tertiary-900 text-base">图表预览</span>
      </div>
      <div class="bg-tertiary-100 flex h-0 w-full flex-grow items-center justify-center" #chartContainerRef>
        <div class="relative aspect-square w-full bg-white">
          @if (shadowSetting(); as setting) {
            <div class="absolute left-1/2 top-1/2 origin-top-left -translate-x-1/2 -translate-y-1/2" [style.scale]="chartScale()">
              <ace-chart [size]="chartSize()" [setting]="setting" [selected]="false"></ace-chart>
            </div>
          }
        </div>
      </div>
      <!--      <div class="mb-2 flex h-10 items-center text-lg">-->
      <!--        <mat-icon svgIcon="editorup:listpoint (节点)" color="primary" class="mr-2 h-5 min-h-5 w-5 min-w-5"></mat-icon>-->
      <!--        <span class="text-tertiary-900 text-base">相似皮肤</span>-->
      <!--      </div>-->
      <!--      <div class="h-50 flex w-full flex-shrink-0"> 相似皮肤 </div>-->
    </div>
  </div>
</div>
