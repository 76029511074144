import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core'

import { Subject, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { ColorPickerComponent } from '#modules/workspace/components/setting-widget/color-picker'
import { ButtonComponent } from '#shared/components/button/button.component'

export interface IMenu {
  text: string
  icon?: string
  callback?: () => void
}

@Component({
  selector: 'ace-menu',
  standalone: true,
  imports: [CommonModule, ButtonComponent, CdkConnectedOverlay, ColorPickerComponent, CdkOverlayOrigin],
  template: `
    <div cdkOverlayOrigin #trigger="cdkOverlayOrigin" (mousemove)="handleOpen()" (mouseleave)="handleClose()">
      <ace-button [text]="text()" [icon]="icon()"></ace-button>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen()"
      [cdkConnectedOverlayPositions]="[positions]"
      [cdkConnectedOverlayPositionStrategy]="_positionStrategy"
      (overlayOutsideClick)="handleOpenChange($event)"
    >
      <div class="light-shadow rounded-lg bg-white p-1" (mousemove)="handleOpen()" (mouseleave)="handleClose()">
        @for (option of options(); track $index) {
          <div class="flex h-10 w-full items-center">
            <ace-button [text]="option.text" [icon]="option.icon" (aceClick)="handleOptionClick(option)"></ace-button>
          </div>
        }
      </div>
    </ng-template>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
  text = input.required<string>()
  icon = input<string>()

  _overlay = inject(Overlay)
  _positionStrategy!: FlexibleConnectedPositionStrategy
  isOpen = signal(false)
  breakClose$ = new Subject<undefined>()
  options = input.required<IMenu[]>()

  positions: ConnectedPosition = {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: 8
  }

  handleOpen() {
    this.isOpen.set(true)
    this.breakClose$.next(undefined)
  }

  handleClose() {
    timer(200)
      .pipe(takeUntil(this.breakClose$))
      .subscribe(() => {
        this.isOpen.set(false)
      })
  }

  handleOpenChange(event: MouseEvent) {
    console.log(event)
  }

  handleOptionClick(option: IMenu) {
    option.callback?.()
  }
}
